import React, { lazy, Suspense } from "react";
import { RingLoader } from "react-spinners";

const CarOptions = lazy(() => import("../../components/caroptions/CarOptions"));
const Header = lazy(() => import("../../components/header/Header"));
const Hero = lazy(() => import("../../components/hero/Hero"));
const AboutUs = lazy(() => import("../../components/aboutus/AboutUs"));
const HowitWorks = lazy(() => import("../../components/howitworks/HowitWorks"));
const UseCases = lazy(() => import("../../components/usecases/UseCases"));
const BecomeHost = lazy(() => import("../../components/becomehost/BecomeHost"));
const RentalOptions = lazy(() =>
  import("../../components/rentaloptions/RentalOptions")
);
const ScrollBtn = lazy(() => import("../../components/scrollbtn/ScrollBtn"));
const Footer = lazy(() => import("../../components/footer/Footer"));

const Main = () => {
  return (
    <React.Fragment>
      <Suspense
        fallback={
          <div
            style={{ height: "100vh" }}
            className="flex items-center justify-center"
          >
            <RingLoader size={150} color={"#fb8500"} />
          </div>
        }
      >
        <Header />
        <Hero />
        <AboutUs />
        <HowitWorks />
        <UseCases />
        <CarOptions />
        <BecomeHost />
        <RentalOptions />
        <ScrollBtn />
        <Footer />
      </Suspense>
    </React.Fragment>
  );
};

export default Main;
