export const capitalizeText = (str) => {
  const newText = str.charAt(0).toUpperCase() + str.slice(1);
  return newText;
};

export const formatNumber = (num) => {
  const units = ["", "K", "M", "B", "T"];
  const unitIndex = Math.floor(Math.log10(num) / 3);
  const unit = units[unitIndex];
  const formattedNumber = (num / Math.pow(1000, unitIndex)).toFixed(1);

  return `${formattedNumber}${unit}`;
};
