import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logos/orange2.png";
import axios from "axios";
import toast from "react-hot-toast";
import { capitalizeText } from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faTrash,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import useWindowDimensions from "../../hooks/useWindowSize";

const url =
  "https://shielded-crag-81600-77b220f4e540.herokuapp.com/api/v1/company";

const states = [
  "abia",
  "adamawa",
  "akwa ibom",
  "anambra",
  "bauchi",
  "bayelsa",
  "benue",
  "borno",
  "cross river",
  "delta",
  "ebonyi",
  "edo",
  "ekiti",
  "enugu",
  "gombe",
  "imo",
  "jigawa",
  "kaduna",
  "kano",
  "katsina",
  "kebbi",
  "kogi",
  "kwara",
  "lagos",
  "nasarawa",
  "niger",
  "ogun",
  "ondo",
  "osun",
  "oyo",
  "plateau",
  "rivers",
  "sokoto",
  "taraba",
  "yobe",
  "zamfara",
  "abuja",
];

const cars = ["sedan", "suv", "mini van", "bus", "luxury", "pick up truck"];

const Business = () => {
  const { register, handleSubmit } = useForm();
  const [selectedCar, setSelectedCar] = useState("");
  const [carQuantity, setCarQuantity] = useState(1);
  const [carsList, setCarsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const { width } = useWindowDimensions();

  useEffect(() => {
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  }, [showToast]);

  const handleCarChange = (event) => {
    setSelectedCar(event.target.value);
  };

  const handleQuantityChange = (event) => {
    setCarQuantity(parseInt(event.target.value));
  };

  const handleAddCar = (e) => {
    e.preventDefault();
    if (selectedCar && carQuantity > 0) {
      const carExists = carsList.find((car) => car.title === selectedCar);

      if (carExists) {
        // Increment quantity if the car already exists in the array
        const updatedCars = carsList.map((car) => {
          if (car.title === selectedCar) {
            return { ...car, quantity: car.quantity + carQuantity };
          }
          return car;
        });

        setCarsList(updatedCars);
      } else {
        // Add new car to the array with the selected quantity
        setCarsList([
          ...carsList,
          { title: selectedCar, quantity: carQuantity },
        ]);
      }

      setSelectedCar("");
      setCarQuantity(1);
    }
  };

  const handleRemoveCar = (carTitle) => {
    const updatedCars = carsList.filter((car) => car.title !== carTitle);
    setCarsList(updatedCars);
  };

  const onSubmit = async (payload) => {
    if (carsList.length < 1) {
      return toast.error("Please select a car");
    }
    width > 768 && toast.loading("Processing...");
    width < 768 && setLoading(true);
    try {
      await axios.post(url, {
        ...payload,
        vehicleType: carsList,
      });
      width < 768 && setLoading(false);
      width < 768 && setSuccess(true);
      width < 768 && setShowToast(true);
      width > 768 && toast.remove(); // Handle the response data
      width > 768 && toast.success("Form submitted successfully");
    } catch (error) {
      console.log(error);
      width < 768 && setLoading(false);
      width < 768 && setSuccess(false);
      width < 768 && setShowToast(true);
      width > 768 && toast.remove();
      width > 768 && toast.error("An error occurred");
    }
  };

  const backtoPage = () => {
    navigate("/");
  };

  return (
    <div className="px-4">
      <h1
        style={{ textDecorationColor: "#fb8500", marginBottom: "25px" }}
        className="text-center text-darkBlue font-medium text-2xl underline my-10"
      >
        Get to know when we launch
      </h1>
      <form
        className="border border-slate-300 p-5"
        style={{
          maxWidth: "800px",
          margin: "0 auto",
          boxShadow: "4px 4px 0 0 #023047",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mb-5 flex items-center justify-between">
          <button onClick={backtoPage}>
            <FontAwesomeIcon className="text-orangeBg" icon={faArrowLeftLong} />
          </button>
          <img className="w-25 h-4" src={Logo} alt="logo" />
        </div>
        <input
          className="block text-baseFont w-full border p-2 rounded-md mb-4 outline-none text-xs focus:ring-0 focus:border-orangeBg"
          type="text"
          {...register("fullName", {
            required: true,
          })}
          placeholder="Full name*"
        />
        <input
          className="block text-baseFont w-full border p-2 rounded-md mb-4 outline-none text-xs focus:ring-0 focus:border-orangeBg"
          type="email"
          {...register("email", {
            required: true,
          })}
          placeholder="Email address*"
        />
        <input
          className="block text-baseFont w-full border p-2 rounded-md mb-4 outline-none text-xs focus:ring-0 focus:border-orangeBg"
          type="number"
          {...register("phoneNumber", {
            required: true,
          })}
          placeholder="Phone Number*"
        />
        <input
          className="block text-baseFont w-full border p-2 rounded-md mb-4 outline-none text-xs focus:ring-0 focus:border-orangeBg"
          type="text"
          {...register("companyName", {
            required: true,
          })}
          placeholder="Company name*"
        />
        <div>
          <div
            className={`flex ${
              width > 768 ? "flex-row" : "flex-col"
            } justify-center ${
              width > 768 ? "items-center" : "items-start"
            } gap-2 text-xs mb-4 py-1`}
          >
            <select
              className={`flex-1 border px-4 py-1 rounded outline-none text-xs focus:ring-0 focus:border-orangeBg ${
                width < 769 && "w-full mb-1"
              }`}
              value={selectedCar}
              onChange={handleCarChange}
            >
              <option value="">Select a vehicle type below</option>
              {cars.map((car, index) => (
                <option key={index} value={car}>
                  {capitalizeText(car)}
                </option>
              ))}
            </select>
            <div className="w-full flex-1 flex items-center justify-center gap-1">
              <p className="text-xs">Enter Quantity:</p>
              <input
                className="border flex-1 px-4 py-1 rounded outline-none text-xs focus:ring-0 focus:border-orangeBg"
                type="number"
                value={carQuantity}
                onChange={handleQuantityChange}
                min={1}
              />
            </div>
            <button
              className={`border border-darkBlue text-white py-1 px-3 rounded bg-darkBlue ${
                width < 769 && "w-full"
              }`}
              onClick={handleAddCar}
            >
              Add vehicle
            </button>
          </div>
          <ul className="p-5 w-full flex flex-col mb-4 gap-3 border rounded">
            {carsList.length < 1 && (
              <p className="w-full text-center text-xs">
                <FontAwesomeIcon
                  className="text-orangeBg"
                  icon={faCircleExclamation}
                />{" "}
                No vehicles have been added, you can add more than one vehicle!
              </p>
            )}
            {carsList &&
              carsList.map((car, index) => (
                <li
                  key={index}
                  className="w-full flex items-center justify-between border border-slate-100 px-2 py-1 rounded bg-slate-100 text-xs"
                >
                  <p>
                    {capitalizeText(car.title)} - Quantity:{" "}
                    {car.quantity.toLocaleString()}
                  </p>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleRemoveCar(car.title);
                    }}
                  >
                    <FontAwesomeIcon className="text-orangeBg" icon={faTrash} />
                  </button>
                </li>
              ))}
          </ul>
        </div>
        <select
          className="block text-baseFont w-full border p-2 rounded-md mb-4 outline-none text-xs focus:ring-0 focus:border-orangeBg"
          {...register("state", {
            required: true,
          })}
        >
          <option selected disabled>
            Select State
          </option>
          {states.map((state, index) => (
            <option value={state} key={index}>
              {capitalizeText(state)}
            </option>
          ))}
        </select>
        <textarea
          className="block text-baseFont w-full border p-2 rounded-md mb-4 outline-none text-xs focus:ring-0 focus:border-orangeBg"
          {...register("itinerary", {
            required: true,
          })}
          placeholder="Itinerary*"
          rows={5}
        ></textarea>
        <textarea
          className="block text-baseFont w-full border p-2 rounded-md mb-4 outline-none text-xs focus:ring-0 focus:border-orangeBg"
          {...register("note", {})}
          placeholder="Note*"
          rows={5}
        ></textarea>
        <div
          className={`w-full mb-4 flex ${
            width > 768 ? "flex-row" : "flex-col"
          } items-center justify-between gap-3`}
        >
          <div className="flex-1 flex items-center justify-start">
            <p className="text-xs w-20">Start Date</p>
            <input
              className="block text-baseFont w-full border p-2 rounded-md outline-none text-xs focus:ring-0 focus:border-orangeBg"
              type="date"
              {...register("startDate", {
                required: true,
              })}
              placeholder="Start Date*"
            />
          </div>
          <div className="flex-1 flex items-center justify-start">
            <p className="text-xs w-20">End Date</p>

            <input
              className="block text-baseFont w-full border p-2 rounded-md outline-none text-xs focus:ring-0 focus:border-orangeBg"
              type="date"
              {...register("endDate", {
                required: true,
              })}
              placeholder="End Date*"
            />
          </div>
        </div>
        <div className="flex items-center justify-center h-5">
          {showToast && (
            <p
              className={`text-xs text-center ${
                success ? "text-emerald-500" : "text-red-500"
              }`}
            >
              {success
                ? "Form submitted, you will be contacted when we launch"
                : "Unable to submit form, please try again later..."}
            </p>
          )}
        </div>
        <button
          id="submit"
          type="submit"
          disabled={loading && true}
          className="hover:text-400 text-baseFont w-full mt-4 mx-auto rounded-md cursor-pointer text-sm bg-darkBlue text-orangeBg py-2"
        >
          {loading ? (
            <div className="p-0 m-0" style={{ transform: "scale(1)" }}>
              <ClipLoader size={12} color={"#fb8500"} />
            </div>
          ) : (
            "Submit"
          )}
        </button>
      </form>
    </div>
  );
};

export default Business;
