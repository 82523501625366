import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Main from "./pages/main/Main";
import { Toaster } from "react-hot-toast";
import Individual from "./pages/waitlist/Individual";
import Business from "./pages/waitlist/Business";

function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/individual" element={<Individual />} />
        <Route path="/company" element={<Business />} />
      </Routes>
      <Toaster
        toastOptions={{
          style: {
            color: "#023047",
            fontSize: "13px",
          },
          success: {
            iconTheme: {
              primary: "#fb8500",
            },
            style: {
              color: "#023047",
            },
          },
        }}
      />
    </React.Fragment>
  );
}

export default App;
