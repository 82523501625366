import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Logo from "../../assets/logos/orange2.png";
import axios from "axios";
import toast from "react-hot-toast";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import useWindowDimensions from "../../hooks/useWindowSize";

const url = "https://shielded-crag-81600-77b220f4e540.herokuapp.com/waitList";

const Individual = () => {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  useEffect(() => {
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  }, [showToast]);

  const onSubmit = async (payload) => {
    width > 768 && toast.loading("Processing...");
    width < 768 && setLoading(true);
    try {
      await axios.post(url, payload);
      width < 768 && setLoading(false);
      width < 768 && setSuccess(true);
      width < 768 && setShowToast(true);
      width > 768 && toast.remove(); // Handle the response data
      width > 768 &&
        toast.success("Submitted successfully, you will be notified!");
    } catch (error) {
      console.log(error);
      width < 768 && setLoading(false);
      width < 768 && setSuccess(false);
      width < 768 && setShowToast(true);
      width > 768 && toast.remove();
      width > 768 && toast.error("An error occurred");
    }
  };

  const backtoPage = () => {
    navigate("/");
  };

  return (
    <div className="px-4">
      <h1
        style={{ textDecorationColor: "#fb8500", marginBottom: "25px" }}
        className="text-center text-darkBlue font-medium text-2xl underline my-10"
      >
        Get to know when we launch
      </h1>
      <form
        className="border border-slate-300 p-5"
        style={{
          maxWidth: "800px",
          margin: "0 auto",
          boxShadow: "4px 4px 0 0 #023047",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mb-5 flex items-center justify-between">
          <button onClick={backtoPage}>
            <FontAwesomeIcon className="text-orangeBg" icon={faArrowLeftLong} />
          </button>
          <img className="w-25 h-4" src={Logo} alt="logo" />
        </div>
        <input
          className="block text-baseFont w-full border p-2 rounded-md mb-4 outline-none text-xs focus:ring-0 focus:border-orangeBg"
          type="text"
          {...register("name", {
            required: true,
          })}
          placeholder="Full name*"
        />
        <input
          className="block text-baseFont w-full border p-2 rounded-md mb-4 outline-none text-xs focus:ring-0 focus:border-orangeBg"
          type="email"
          {...register("email", {
            required: true,
          })}
          placeholder="Email address*"
        />
        <input
          className="block text-baseFont w-full border p-2 rounded-md mb-4 outline-none text-xs focus:ring-0 focus:border-orangeBg"
          type="number"
          {...register("phoneNumber", {
            required: true,
          })}
          placeholder="Phone Number*"
        />
        <select
          className="block text-baseFont w-full border p-2 rounded-md mb-4 outline-none text-xs focus:ring-0 focus:border-orangeBg"
          {...register("customerType", {
            required: true,
          })}
        >
          <option value={"company"}>Company</option>
          <option value={"customer"}>Customer</option>
          <option value={"host"}>Host</option>
        </select>
        <div className="flex items-center justify-center h-5">
          {showToast && (
            <p
              className={`text-xs text-center ${
                success ? "text-emerald-500" : "text-red-500"
              }`}
            >
              {success
                ? "Form submitted, you will be contacted when we launch"
                : "Unable to submit form, please try again later..."}
            </p>
          )}
        </div>
        <button
          id="submit"
          type="submit"
          disabled={loading && true}
          className="hover:text-400 text-baseFont w-full mt-4 mx-auto rounded-md cursor-pointer text-sm bg-darkBlue text-orangeBg py-2"
        >
          {loading ? (
            <div className="p-0 m-0" style={{ transform: "scale(1)" }}>
              <ClipLoader size={12} color={"#fb8500"} />
            </div>
          ) : (
            "Join Waitlist"
          )}
        </button>
      </form>
    </div>
  );
};

export default Individual;
